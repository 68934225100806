import React, { useEffect, useState } from "react";
import "./GeneralDetails.css";
import MainGeneral from "./MainGeneral";
import { Button } from "react-bootstrap";
import AddOn from "./AddOn";
import TravellerDetails from "../travellerdetails/TravellerDetails";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../loader/Loader";
import { RedirectUrlAfterLogin } from "../../repeatComponent/RedirectUrlAfterLogin";

const GeneralDetails = (Props: any) => {
  const [idData, setIdData] = useState({
    order_id: "",
    user_id: { firstname: "" },
    old_travel_no_of_traveler: "",
    travel_boarding_point: { boarding: "" },
    boarding: "",
    travel_date: "",
    amountPaid: "",
    totalAmount: "",
    payment_status: "",
    pdf_url: "",
    TourPrice: "",
    firstName: "",
  });
  const [addOnTotalAmount, setAddOnTotalAmount] = useState<number>(0);

  const [load, setLoad] = useState<boolean>(true);
  const [termsAgreed, setTermsAgreed] = useState<boolean>(false);
  const [checkoutData, setCheckoutData] = useState<any>(null);

  const [otherChareges, setOtherCharges] = useState<any>(null);
  const [panNumber, setPanNumber] = useState<string>("");
  const [paymentType, setPaymentType] = useState<string>("Full"); // Assuming "Full" or "Partial"
  const [domestincorInternational, setDomesticOrInternational] =
    useState<string>("domestic"); // "domestic" or "international"
  const location = useLocation();
  const { id } = location.state || {};
  const { checkoutId } = useParams();

  const fetchData = async (_id: string) => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-historyBy-id?id=${_id}`
      );
      if (response.data.success) {
        setIdData(response.data.getData);
      }
    } catch (error) {
      console.log(error);
    }
    setLoad(false);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);
  const calculateTotalRemainingAmount_2 = () => {
    const totalRemainingAmount: any =
      Number(idData.amountPaid) - Number(idData.TourPrice);


    if (totalRemainingAmount > 0) {
      return totalRemainingAmount;
    } else {
      return 0;
    }
  };
  const calculateTotalRemainingAmount = () => {
    if (calculateTotalRemainingAmount_2() == 0) {
      const totalRemainingAmount: any =
        Number(idData.totalAmount) +
        addOnTotalAmount -
        Number(idData.amountPaid);
      if (totalRemainingAmount > 0) {
        return totalRemainingAmount;
      } else {
        return 0;
      }
    } else {
      const totalRemainingAmount: any =
        addOnTotalAmount - calculateTotalRemainingAmount_2();
      if (totalRemainingAmount > 0) {
        return totalRemainingAmount;
      } else {
        return 0;
      }
    }
  };
  useEffect(() => {
    loadCheckoutData();
    AllotherCharges();
  }, []);
  const loadCheckoutData = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/get-appliedTourById`,
      {
        id: id,
      }
    );

    if (response?.data?.success) {
      setCheckoutData(response?.data);
      // setAfterCouponPrice(response?.data?.getData?.finalPrice);
      // const travellerDetails = {
      //   value: response?.data?.getData,
      // };
      // dispatch(getTraveller(travellerDetails));
      // setDomesticorInternational(
      //   response?.data?.getData?.domesticorInternational
      // );
      // setState(response?.data?.getData.user_id.state);
      // setGst(response?.data.applyTourbatchId[0].gst);
      // setTcs(response.data.applyTourbatchId[0].tcs);
    }
  };
  const responseValue = checkoutData?.getData?.old_travel_no_of_traveler;

  const AllotherCharges = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get-all-other-charge`)
      .then((res) => {
        if (res.data.success) {
          setOtherCharges(res.data.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => console.log(err, "error in catch"));
  };

  const handlePayNow = () => {
    if (calculateTotalRemainingAmount()==0) {
      return
    }
    else{
    makePayment();
    }
  };

  const makePayment = async () => {
    try {
      let totalAmountToSend = calculateTotalRemainingAmount();
      let amountPaid = totalAmountToSend;

      let paymentObj = {
        amount_paid: amountPaid,
        bookingPrice: checkoutData?.applyTourbatchId[0]?.bookPrice,
        cgst: otherChareges?.[0]?.cgst,
        checkout_id: id,
        id: id,
        cusGSTNo: "",
        cuscompanyAddress: "",
        cuscompanyName: "",
        igst: otherChareges?.[0]?.igst,
        isOuter: true,
        // partial_payment: idData.old_travel_no_of_traveler,
        partial_payment:
          checkoutData?.applyTourbatchId[0]?.bookPrice * responseValue,
        payment_status: paymentType === "Partial" ? "Partial" : "Complete",
        sgst: otherChareges?.[0]?.sgst,
        total_amount: totalAmountToSend,
        useremail: idData.user_id.firstname,
        // username: idData.user_id.firstname,
        // user_id: idData.user_id.firstname,

        username: checkoutData?.getData?.user_id?.email,
        user_id: checkoutData?.getData?.user_id?._id,
        panNumber: panNumber,
        bookedBy: checkoutData?.getData?.user_id?._id,
        // bookedBy: idData.user_id.firstname,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ccavRequestHandler`,
        paymentObj
      );

      if (response.data.success) {
        window.location.href = response.data.encryptedRequest;
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };

  return (
    <>
      <RedirectUrlAfterLogin />
      {load ? <Loader /> : ""}
      <div className="container forupeergeneral mt-5 mb-5">
        <div className="row">
          <div className="col-lg-3">
            <p className="smallcard-general">
              <div
                className="nav flex-column nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-linkgeneral dashgeneral active"
                  id="v-pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-home"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-home"
                  aria-selected="true"
                >
                  <span className="col-md-6 for-visible-tab">General</span>
                </button>
                <button
                  className="nav-linkgeneral dashgeneral for-visible-tab"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profile"
                  aria-selected="false"
                >
                  Traveller Details
                </button>
                <button
                  className="nav-linkgeneral dashgeneral for-visible-tab"
                  id="v-pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-profileadd"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-profileadd"
                  aria-selected="false"
                >
                  Add-on
                </button>
              </div>
            </p>
          </div>

          <div className="col-lg-9 tab-content mt-4" id="v-pills-tabContent">
            <div
              className="tab-pane fade show active"
              id="v-pills-home"
              role="tabpanel"
              aria-labelledby="v-pills-home-tab"
            >
              <p className="bigcard-general">
                <div id="v-pills-tabContent">
                  <div className="card-body">
                    <MainGeneral trav={idData} />
                  </div>
                </div>
              </p>

              <p className="bigcard-general mt-4">
                <div id="v-pills-tabContent">
                  <div className="card-body">
                    <form className="row g-3 mb-4 pt-2 formgeneral2">
                      <div className="col-md-4">
                        <label className="form-label label-generalpart">
                          Add-ons Amount
                        </label>
                        <input
                          type="text"
                          className="form-control placegeneral3"
                          id="inputEmail4"
                          value={`₹ ${addOnTotalAmount}`}
                          readOnly
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label label-generalpart">
                          Amount paid (Add-ons)
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control placegeneral3"
                          id="inputEmail4"
                          value={`₹ ${calculateTotalRemainingAmount_2()}`}
                        />
                      </div>
                      <div className="col-md-4">
                        <label className="form-label label-generalpart">
                          Total Remaining Amount
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control placegeneral3"
                          id="inputEmail4"
                          value={`₹ ${calculateTotalRemainingAmount()}`}
                        />
                      </div>
                      <div className="generalbtn-container pt-4">
                        {/* <Button className="cancl-btn">CANCEL</Button> */}
                        <Button onClick={handlePayNow} className="paynow-btn">
                          PAY NOW
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              </p>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-profile"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <p className="bigcard-general">
                <div id="v-pills-tabContent">
                  <div className="card-body px-2">
                    <TravellerDetails travllerDeatils={idData} />
                  </div>
                </div>
              </p>
            </div>

            <div
              className="tab-pane fade"
              id="v-pills-profileadd"
              role="tabpanel"
              aria-labelledby="v-pills-profile-tab"
            >
              <p className="bigcard-general">
                <div id="v-pills-tabContent">
                  <div className="card-body">
                    <AddOn onTotalAmountChange={setAddOnTotalAmount} />
                  </div>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneralDetails;
